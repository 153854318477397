export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'SET_PDFS_BEING_PROCESSED': {
      return { ...state, ...payload }
    }
    case 'UNSET_PDFS_BEING_PROCESSED': {
      return { }
    }
    default: {
      return state
    }
  }
}