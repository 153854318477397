import * as rax from "retry-axios";
import Axios from "axios";
import UserAgentApp from "../MSAL/UserAgentApp/UserAgentApp";
let instance = null;
export default class Xhr {
  ax = null;
  constructor() {
    if (instance) {
      return instance;
    }
    this.ax = this.createInstance();
    instance = this;
    return instance;
  }

  createInstance = () => {
    const opts = {};
    const axiosInstance = Axios.create(opts);
    axiosInstance.defaults.raxConfig = {
      instance: axiosInstance,
      retry: 3,
      noResponseRetries: 3,
      retryDelay: 100,
      onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
        let notifMessage = `HTTP Request failed. Retrying (${cfg.currentRetryAttempt}/3)`;
        alert(notifMessage);
      },
    };
    const interceptorId = rax.attach(axiosInstance);
    return axiosInstance;
  };
  get = async (url, opts = {}) => {
    return await this.ax.get(url, opts);
  };
  post = async (url, requestData, opts = {}) => {
    const UAA = UserAgentApp.getInstance();
    await UAA.acquireTokenSilent();
    requestData["token"] = localStorage.getItem("msal.idtoken");
    return await this.ax.post(url, requestData, opts);
  };
  update = async (url, requestData, opts = {}) => {
    return await this.ax.put(url, requestData, opts);
  };
  del = async (url, opts = {}) => {
    return await this.ax.delete(url, opts);
  };
}
