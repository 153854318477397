import { Configuration, AuthenticationParameters } from "msal";

export const UserAgentAppConfig: Configuration = {
  auth: {
    clientId: "42b0d10f-ff16-47b5-bdd7-e7453dd65d8c",
    authority:
      "https://login.microsoftonline.com/300a909b-f951-45af-94e3-456191dbb8b9",
    redirectUri: process.env["REACT_APP_PUBLIC_URL_1"],
    postLogoutRedirectUri: process.env["REACT_APP_PUBLIC_URL_1"],
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const userRequest: AuthenticationParameters = {
  scopes: ["openid", "profile", "User.Read"],
};
