export default (state = 0, { type, payload }) => {
  switch (type) {
    case 'SET_NOTIFICATION_COUNT': {
      return payload
    }
    case 'UNSET_NOTIFICATION_COUNT': {
      return 0
    }
    default: {
      return state
    }
  }
}