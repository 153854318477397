import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authUserReducer from "resources/redux/reducers/setAuthUserReducer";
import recentWorkspacesReducer from "resources/redux/reducers/setRecentWorkspacesReducer";
import sharedWorkspacesReducer from "resources/redux/reducers/setSharedWorkspacesReducer";
import currentWorkspaceReducer from "resources/redux/reducers/setCurrentWorkspaceReducer";
import currentWorkspaceNavigationReducer from "resources/redux/reducers/setCurrentWorkspaceNavigationReducer";
import currentWorkspaceDocumentsReducer from "resources/redux/reducers/setCurrentWorkspaceDocumentsReducer";
import currentWorkspaceDocumentReducer from "resources/redux/reducers/setCurrentWorkspaceDocumentReducer";
import pdfsBeingProcessedReducer from "resources/redux/reducers/setPdfsBeingProcessedReducer";
import pdfsProcessedReducer from "resources/redux/reducers/setPdfsProcessedReducer";
import pdfsDeletedReducer from "resources/redux/reducers/setPdfsDeletedReducer";
import pdfsProcessedDetailsReducer from "resources/redux/reducers/setPdfsProcessedDetailsReducer";
import currentWorkspaceBasketReducer from "resources/redux/reducers/setCurrentWorkspaceBasketReducer";
import isBasketUpToDateReducer from "resources/redux/reducers/setIsBasketUpToDateReducer";
import notificationsReducer from "resources/redux/reducers/setNotificationsReducer";
import notificationCountReducer from "resources/redux/reducers/setNotificationCountReducer";
import newNotificationsBoolReducer from "resources/redux/reducers/setNewNotificationsBoolReducer";
import noOfUploadsReducer from "resources/redux/reducers/setNoOfUploadsReducer";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel1,
};

const rootReducer = combineReducers({
  authUser: authUserReducer,
  currentWorkspace: currentWorkspaceReducer,
  currentWorkspaceNavigation: currentWorkspaceNavigationReducer,
  currentWorkspaceDocuments: currentWorkspaceDocumentsReducer,
  currentWorkspaceDocument: currentWorkspaceDocumentReducer,
  recentWorkspaces: recentWorkspacesReducer,
  sharedWorkspaces: sharedWorkspacesReducer,
  pdfsBeingProcessed: pdfsBeingProcessedReducer,
  pdfsProcessed: pdfsProcessedReducer,
  pdfsProcessedDetails: pdfsProcessedDetailsReducer,
  pdfsDeleted: pdfsDeletedReducer,
  currentWorkspaceBasket: currentWorkspaceBasketReducer,
  isBasketUpToDate: isBasketUpToDateReducer,
  notifications: notificationsReducer,
  notificationCount: notificationCountReducer,
  isNewNotifications: newNotificationsBoolReducer,
  noOfUploads: noOfUploadsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let state = {
  authUser: {},
  currentWorkspace: {},
  currentWorkspaceNavigation: {},
  currentWorkspaceDocuments: {},
  currentWorkspaceDocument: {},
  recentWorkspaces: {},
  sharedWorkspaces: {},
  pdfsBeingProcessed: {},
  pdfsProcessed: {},
  pdfsProcessedDetails: [],
  pdfsDeleted: [],
  currentWorkspaceBasket: {},
  isBasketUpToDate: {},
  notifications: [],
  notificationCount: 0,
  isNewNotifications: false,
  noOfUploads: 0,
};

const store = createStore(
  persistedReducer,
  state,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
