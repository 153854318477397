
export default (state = [], { type, payload }) => {
  switch (type) {
    case 'SET_NOTIFICATIONS': {
      return [ ...state, ...payload ]
    }
    case 'UNSET_NOTIFICATIONS': {
      return []
    }
    default: {
      return state
    }
  }
}