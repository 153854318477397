export default (state = [], { type, payload }) => {
  switch (type) {
    case "SET_PDFS_DELETED": {
      return [...state, ...payload];
    }
    case "UNSET_PDFS_DELETED": {
      return [];
    }
    default: {
      return state;
    }
  }
};