export default (state = {}, { type, payload }) => {
  switch (type) {
    case "SET_CURRENT_WORKSPACE_DOCUMENT": {
      if (!payload.pages) {
        payload.pages = [];
      }
      return { ...state, ...payload };
    }
    case "UNSET_CURRENT_WORKSPACE_DOCUMENT": {
      return {};
    }
    default: {
      return state;
    }
  }
};
