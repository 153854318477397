export default (state = false, { type, payload }) => {
  switch (type) {
    case 'SET_NEW_NOTIFICATIONS_BOOL': {
      return payload
    }
    case 'UNSET_NEW_NOTIFICATIONS_BOOL': {
      return false
    }
    default: {
      return state
    }
  }
}