export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'SET_AUTH_USER': {
      return { ...state, ...payload }
    }
    case 'UNSET_AUTH_USER': {
      return { }
    }
    default: {
      return state
    }
  }
}